<template>
    <div class="goods-list" v-loading="loading">
        <!-- 搜索关键字 -->
        <!-- <div class="search_bread" v-if="keyword">
            <span>Search Results：</span>
            <span class="keyword">'{{ keyword }}'</span>
        </div>
        <div class="search_bread" v-else-if="!keyword && catewords">
            <span>Search Results:：</span>
            <span class="keyword">'{{ catewords }}'</span>
        </div> -->

        <!-- 品牌过滤记录区 -->
        <div class="attr_filter" v-if="catewords">
            <el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
                <span v-if="choosedBrand" class="title">Brand：</span>
                {{ choosedBrand.brand_name }}
            </el-tag>
            <span v-for="item in attributeList" :key="item.attr_id">
                <el-tag type="info" v-if="item.selectedValue" closable @close="colseAttr(item)" effect="plain">
                    <span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
                    {{ item.selectedValue }}
                </el-tag>
            </span>
        </div>

        <!-- 品牌属性筛选区 -->
        <template>
            <div class="category" v-if="brandList.length || attributeList.length">
                <!-- 品牌 -->
                <div class="brand">
                    <div class="table_head">Brand：</div>
                    <div class="table_body">
                        <div class="initial">
                            <span type="info" effect="plain" hit @mouseover="handleChangeInitial('')">All brands</span>
                            <span type="info" effect="plain" hit v-for="item in brandInitialList" :key="item"
                                @mouseover="handleChangeInitial(item)">{{ (item || '').toUpperCase() }}</span>
                        </div>
                        <div class="content">
                            <el-card v-for="item in brandList" :key="item.id" body-style="padding: 0;height: 100%;"
                                shadow="hover" v-show="currentInitial === '' || item.brand_initial === currentInitial"
                                class="brand-item">
                                <el-image :src="$img(item.image_url || defaultGoodsImage)" :alt="item.brand_name"
                                    :title="item.brand_name" fit="contain" @click="onChooseBrand(item)" />
                            </el-card>
                        </div>
                    </div>
                </div>

                <!-- 属性 -->
                <div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
                    <div class="table_head">{{ item.attr_name }}：</div>
                    <div class="table_body">
                        <div class="content">
                            <span v-for="subitem in item.child" :key="subitem.attr_value_id">
                                <el-checkbox :label="subitem.attr_value_name" v-if="item.isMuiltSelect"
                                    :checked="subitem.selected"
                                    @change="setAttrSelectedMuilt(item, subitem)"></el-checkbox>
                                <el-link :underline="false" v-else @click="setAttrSelected(item, subitem)">{{
                                    subitem.attr_value_name }}</el-link>
                            </span>
                        </div>
                    </div>
                    <div class="table_op">
                        <el-button size="small" icon="el-icon-circle-plus-outline"
                            @click="setMuiltChoose(item)">多选</el-button>
                    </div>
                </div>
            </div>
        </template>

        <div class="list-wrap">
            <div class="goods-recommended" v-if="cargoList.length">
                <goods-recommend-v :title="$lang('hotSales')" :page-size="cargoList.length < 5 ? 2 : 5" />
            </div>
            <div class="list-right">
                <!-- 排序筛选区 -->
                <div>
                    <div class="sort">
                        <div class="item" @click="changeSort('')">
                            <div class="item-name">products list</div>
                        </div>
                        <!-- <div class="item" @click="changeSort('sale_num')">
                <div class="item-name">销量</div>
                <i
                  v-if="filters.order === 'sale_num' && filters.sort === 'desc'"
                  class="el-icon-arrow-down el-icon--down"
                />
                <i v-else class="el-icon-arrow-up el-icon--up" />
              </div> -->
                        <!-- <div class="item" @click="changeSort('discount_price')">
                            <div class="item-name">price</div>
                            <i v-if="filters.order === 'discount_price' && filters.sort === 'desc'"
                                class="el-icon-arrow-down el-icon--down" />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div> -->
                        <!-- <div class="item-other">
                    <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
                  </div>
                  <div class="item-other">
                    <el-checkbox label="自营" v-model="is_own"></el-checkbox>
                  </div>
                  <div class="input-wrap">
                    <div class="price_range">
                      <el-input placeholder="最低价格" size="small" v-model="filters.min_price"></el-input>
                      <span>—</span>
                      <el-input placeholder="最高价格" size="small" v-model="filters.max_price"></el-input>
                    </div>
                    <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
                  </div> -->
                    </div>
                </div>
                <div class="cargo-list" v-if="cargoList.length">
                    <div class="goods-info">
                        <div class="item" v-for="(item, index) in cargoList" :key="item.goods_id"
                            @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
                            <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })"
                                @error="imageError(index)" />
                            <div class="price-wrap">
                                <div class="price">
                                    <p>$</p>
                                    {{ item.discount_price }}
                                </div>
                                <div class="market-price">${{ item.market_price }}</div>
                            </div>
                            <div class="goods-name">{{ item.goods_name }}</div>
                            <div class="sale-num">
                                <p>{{ item.sale_num || 0 }}</p> people pay
                            </div>
                            <!-- <div class="shop_name">{{item.site_name}}</div> -->
                            <div class="saling">
                                <!-- <div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
                  <div class="is_own" v-if="item.is_own == 1">自营</div>
                  <div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div> -->
                            </div>
                            <!-- <div class="item-bottom">
                    <div v-if="!item.isCollection" class="collection" @click.stop="editCollection(item)">
                      <i class="iconfont iconlike"></i>
                      收藏
                    </div>
                    <div v-if="item.isCollection" class="collection" @click.stop="editCollection(item)">
                      <i class="iconfont iconlikefill ns-text-color"></i>
                      取消收藏
                    </div>
                    <div class="cart" @click.stop="addToCart(item)">
                      <i class="el-icon-shopping-cart-2"></i>
                      加入购物车
                    </div>
                </div>-->
                        </div>
                    </div>
                    <div class="pager">
                        <el-pagination background :pager-count="5" :total="total" prev-text="Previous page"
                            next-text="Next page" :current-page.sync="currentPage" :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
                            hide-on-single-page></el-pagination>
                    </div>
                </div>
                <div class="empty" v-else>
                    <div class="ns-text-align">The product you are looking for was not found. Try it under different
                        conditions
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import GoodsRecommendV from "@/components/GoodsRecommendV";

import { goodsSkuPage } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { relevanceinfo, goodsCategoryInfo } from "@/api/goods/goodscategory"
import { addCollect, deleteCollect, isCollect } from "@/api/goods/goods_collect"

export default {
    name: "list",
    components: {
        BreadCrumbs,
        GoodsRecommendV
    },
    data: () => {
        return {
            cargoList: [],
            shopList: [], // 店铺列表
            brandList: [], // 品牌列表
            attributeList: [], // 属性列表
            brandInitialList: [],
            currentInitial: "", // 当前选择品牌分区
            choosedBrand: "", // 已选择的品牌,
            hasChoosedAttrs: false, // 忆选择的属性
            total: 0,
            // keyword: "",
            catewords: '',
            currentPage: 1,
            pageSize: 16,
            is_free_shipping: 0,
            is_own: "",
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                platform_coupon_type: 0
            },
            loading: true,
            whetherCollection: 0
        }
    },
    props: {
        keyword: { type: String, default: "" },
        categoryId: { type: [String, Number], default: "" },
        categoryLevel: { type: [String, Number], default: "" },
        brandId: { type: String, default: "" },
        platformCouponType: { type: Number, default: 0 },
    },
    created() {
        this.keyword = this.$props.keyword
        
        this.filters.category_id = this.$props.categoryId
        this.filters.category_level = this.$props.categoryLevel
        this.filters.brand_id = this.$props.brandId
        this.filters.platform_coupon_type = this.$props.platformCouponType

        this.getGoodsList()
        if (this.filters.category_id) {
            this.getRelevanceinfo()
            this.categorySearch()
        }
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"]),
    },
    methods: {
        /**
         * 商品分类搜索
         */
        categorySearch() {
            goodsCategoryInfo({
                category_id: this.filters.category_id
            }).then(res => {
                if (res.code == 0 && res.data) {
                    this.catewords = res.data.category_full_name
                }

            }).catch(err => {

            })
        },
        addToCart(item) {
            this.$store
                .dispatch('cart/add_to_cart', item)
                .then(res => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => err);
        },
        async isCollect(item) {
            await isCollect({ goods_id: item.goods_id }).then(res => {
                this.whetherCollection = res.data
                if (this.whetherCollection == 0) {
                    item.isCollection = false
                } else {
                    item.isCollection = true
                }
            })
                .catch(err => err);
        },
        async editCollection(item) {
            await this.isCollect(item)
            const { goods_id, sku_id, site_id, sku_name, sku_price, sku_image } = item;
            if (this.whetherCollection == 0) {
                await addCollect({ goods_id, sku_id, site_id, sku_name, sku_price, sku_image })
                    .then(res => {
                        this.$message({
                            message: '收藏成功',
                            type: 'success'
                        });
                        item.isCollection = true
                    })
                    .catch(err => err);
            } else {
                await deleteCollect({
                    goods_id
                }).then(res => {
                    if (res.data > 0) {
                        this.$message({
                            message: '取消收藏成功',
                            type: 'success'
                        });
                        item.isCollection = false
                    }
                })
                    .catch(err => err);
            }
        },
        getGoodsList() {
            const currentArr = []
            if (this.attributeList) {
                this.attributeList.forEach(item => {
                    if (item.child) {
                        item.child.forEach(subitem => {
                            if (subitem.selected) {
                                currentArr.push({
                                    attr_id: item.attr_id,
                                    attr_value_id: subitem.attr_value_id
                                })
                            }
                        })
                    }
                })
            }

            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.siteId,
                keyword: this.keyword,
                attr: currentArr.length > 0 ? JSON.stringify(currentArr) : "",
                ...this.filters
            }

            goodsSkuPage(params || {})
                .then(res => {
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.cargoList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },

        getRelevanceinfo() {
            const params = {
                category_id: this.filters.category_id,
                category_level: this.filters.category_level
            }
            relevanceinfo(params)
                .then(res => {
                    const { brand_list, attribute_list, brand_initial_list } = res.data
                    this.brandList = brand_list
                    this.attributeList = attribute_list
                    this.brandInitialList = brand_initial_list
                })
                .catch(err => err)
        },

        onChooseBrand(brand) {
            this.choosedBrand = brand
            this.filters.brand_id = brand.id
            this.getGoodsList()
        },

        setMuiltChoose(attr) {
            this.$set(attr, "isMuiltSelect", !attr.isMuiltSelect)
            this.getGoodsList()
        },

        setAttrSelected(item, subitem) {
            if (item.child) {
                item.child.forEach(attr => {
                    this.$set(attr, "selected", false)
                })
            }

            this.$set(subitem, "selected", true)
            this.$set(item, "selectedValue", subitem.attr_value_name)
            this.getGoodsList()
        },

        setAttrSelectedMuilt(item, subitem) {
            this.$set(subitem, "selected", !subitem.selected)
            var selectedValueArray = []

            if (subitem.selected) {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) == -1) {
                    selectedValueArray.push(subitem.attr_value_name)
                }
            } else {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) !== -1) {
                    selectedValueArray.splice(selectedValueArray.indexOf(subitem.attr_value_name), 1)
                }
            }

            this.$set(item, "selectedValue", selectedValueArray.toString())
            this.getGoodsList()
        },

        colseBrand() {
            this.choosedBrand = ""
            this.filters.brand_id = ""
            this.getGoodsList()
        },

        colseAttr(item) {
            item.selectedValue = ""
            item.child.forEach(subitem => {
                subitem.selected = false
            })

            item.isMuiltSelect = false

            this.getGoodsList()
        },

        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsList()
        },
        handlePriceRange() {
            this.getGoodsList()
        },
        handleChangeInitial(initial) {
            this.currentInitial = initial
        },
        changeSort(type) {
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }

            this.getGoodsList()
        }
    },
    watch: {
        is_free_shipping: function (val) {
            this.filters.is_free_shipping = val ? 1 : ""
            this.getGoodsList()
        },
        is_own: function (val) {
            this.filters.is_own = val ? 1 : ""
            this.getGoodsList()
        },
        '$props.categoryId': function(val) {
            this.loading = true
            this.filters.category_id = val
            this.getGoodsList()
        },
        '$props.categoryLevel': function(val) {
            this.loading = true
            this.filters.category_level = val
            this.getGoodsList()
        }
    }
};
</script>

<style lang="scss" scoped>
.goods-list {
    background: #fff;
    padding-top: 10px;
}

.search_bread {
    display: inline-block;
    font-size: 14px;
    margin: 0px auto;
    width: 100%;
    padding: 10px;
}

.selected_border {
    border: 1px solid $base-color;
}

.attr_filter {
    .el-tag {
        margin-right: 5px;
        margin-bottom: 10px;
        border-radius: 0;

        .title {
            color: $base-color;
            font-size: 15px;
        }
    }
}

.category {
    margin: 0 auto 10px auto;
    border: 1px solid #eeeeee;

    .brand {
        border-bottom: 1px solid #eeeeee;
        display: flex;
        flex-direction: row;

        &:last-child {
            border-bottom: none;
        }

        .table_head {
            width: 140px;
            min-width: 140px;
            border-right: 1px solid #eeeeee;
            padding-left: 10px;
            padding-top: 10px;
            background-color: #f2f6fc;
        }

        .table_body {
            flex-grow: 1;

            .initial {
                margin: 5px auto 10px 10px;

                span {
                    border: 0;
                    margin: 0;
                    padding: 5px 10px;
                    border: 0;
                    border-radius: 0;

                    &:hover {
                        background-color: $base-color;
                        color: #ffffff;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 10px;
                width: 900px;

                .brand-item {
                    margin-right: 10px;
                    margin-top: 10px;
                }

                .el-card {
                    width: 125px;
                    height: 45px;

                    &:hover {
                        border: 1px solid $base-color;
                        cursor: pointer;
                    }
                }

                span {
                    margin: auto 25px;
                }
            }
        }

        .table_op {
            margin-top: 5px;
            margin-right: 5px;
        }

        .el-image {
            width: 100%;
            height: 100%;
        }
    }
}

.list-wrap {
    overflow: hidden;
}

.goods-recommended {
    width: 200px;
    background-color: #fff;
    float: left;
    margin-right: 10px;
}

.sort {
    display: flex;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;

        &:hover {
            background: $base-color;
            color: #fff;
        }
    }

    .item-other {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;
    }

    .input-wrap {
        display: flex;
        align-items: center;

        .price_range {
            margin-left: 60px;
        }

        span {
            padding-left: 10px;
        }

        .el-input {
            width: 150px;
            margin-left: 10px;
        }

        .el-button {
            margin: 0 17px;
        }
    }

    >div:first-child {
        border-left: 1px solid #f1f1f1;
    }
}

.goods-info {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    .item {
        width: 220px;
        margin: 10px 20px 0 0;
        border: 1px solid #eeeeee;
        padding-top: 10px;
        position: relative;
        padding-bottom: 5px;

        &:nth-child(4n) {
            margin-right: initial !important;
        }

        &:hover {
            border: 1px solid $base-color;
        }

        .img-wrap {
            width: 198px;
            height: 198px;
            cursor: pointer;
            padding: 10px;
        }

        .goods-name {
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            height: 50px;
            cursor: pointer;

            &:hover {
                color: $base-color;
            }
        }

        .price-wrap {
            padding: 0 10px;
            display: flex;
            align-items: center;

            .price {
                display: flex;
                color: $base-color;
                font-size: $ns-font-size-lg;

                p {
                    font-size: $ns-font-size-base;
                    display: flex;
                    align-items: flex-end;
                }
            }

            .market-price {
                color: #838383;
                text-decoration: line-through;
                margin-left: 10px;
            }
        }

        .sale-num {
            padding: 0 10px;
            display: flex;
            color: #838383;
            font-size: $ns-font-size-sm;

            p {
                color: #4759a8;
            }
        }

        .shop_name {
            padding: 0 10px;
            display: flex;
            color: #838383;
        }

        .saling {
            padding: 0 10px;
            display: flex;
            font-size: $ns-font-size-sm;
            line-height: 1;

            .free-shipping {
                background: $base-color;
                color: #ffffff;
                padding: 3px 4px;
                border-radius: 2px;
                margin-right: 5px;
            }

            .is_own {
                color: #ffffff;
                background: #ff850f;
                border: 1px solid;
                margin-right: 5px;
                display: flex;
                align-items: center;
                padding: 3px 4px;
                border-radius: 2px;
            }

            .promotion-type {
                color: $base-color;
                border: 1px solid $base-color;
                display: flex;
                align-items: center;
                padding: 1px 3px;
            }
        }

        .item-bottom {
            display: flex;
            margin-top: 5px;

            .collection {
                flex: 1;
                border: 1px solid #e9e9e9;
                border-right: none;
                border-left: none;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .cart {
                flex: 2;
                border: 1px solid #e9e9e9;
                border-right: none;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}

.empty {
    margin-top: 45px;
}

.pager {
    text-align: center;
    margin-top: 30px;
}
</style>